@tailwind base;
@tailwind components;
@tailwind utilities;

/* All components */
html {
  background-color: #f9fafb;
  font-family: "Inter", sans-serif !important;
}
.error-text {
  color: #b40e0e;
  margin-top: 2%;
  margin-left: 4%;
}
.error-text-lg {
  color: #b40e0e;
  margin-top: -20px;
  margin-bottom: 10px;
  margin-left: 4%;
}
.require-field {
  color: red;
  font-size: large;
}

.form-control {
  margin-bottom: 1rem;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

/* Get Cedula page  */
.logo-in-cedula {
  position: absolute;
  height: 150px;
  left: 50%;
  transform: translate(-50%);
  top: 10px;
}

.card-cedula {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
}
.card-encounters {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 550px;
  max-width: 800px;
}

.e-m-width {
  width: 900px;
}
.title-cedula {
  color: #374151;
  font-size: 28px;
  line-height: 36px;
  margin-top: 10px;
  margin-bottom: 25px;
  text-align: center;
}
.title-get-data {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 25px;
  margin-left: 65px;
}

.input-cedula {
  border: 1px solid #d1d5db;
  height: 42px;
}

.btn-sig {
  background-color: #be1622;
  height: 42px;
}

.ver-inscripcion {
  color: #be1622;
}

.form-container {
  width: 695px;
}

/* Personal data page */
.logo-in-pd {
  width: 154.89px;
  height: 87.93px;
}

.pd-title {
  color: #374151;
  font-size: 28px;
  font-weight: 800;
  line-height: 10px;
  margin-bottom: 32px;
  margin-left: 65px;
}

section {
  background: white;
}
.left-track {
  width: 337px;
}
.track-inscription {
  /* margin-left: 20px; */
  margin-top: 20px;
}

.btn-my-inscription {
  color: #8527aa;
}

/* Phone layout */
@media (max-width: 480px) {
  .track-inscription {
    visibility: hidden;
    margin-bottom: -200px;
  }
  .e-m-width {
    width: auto;
  }

  .phone-title {
    text-align: center;
    font-size: 1.4rem;
    width: 100%;
    margin-left: 0;
  }

  /* get cedula */
  .phone-lout-gc {
    padding-top: 10rem;
  }
  .title-get-data {
    margin-left: 18px;
  }

  /*personal data page*/
  .logo-in-phone {
    position: relative;
    right: 30px;
  }
  .phone-title-pd {
    margin-left: 15px;
  }
  .phone-margin-pd {
    margin: 0;
  }
  .phone-pad-pd {
    padding: 1rem;
  }

  /* Mision data */
  .phone-pad-md {
    padding: 1rem;
  }
  .phone-layout-md {
    margin: 0;
  }
  .f-issue-lout-ph {
    margin-bottom: -20px;
    row-gap: 0;
  }
  .ph-lout-f-issue-input {
    margin-top: -5px;
    margin-bottom: 20px;
  }

  /* Confirmation page */
  .phone-pad-c {
    padding-top: 7.25rem;
    padding-bottom: 0.25rem;
  }
  .card-cedula-ph {
    width: 100%;
  }

  .card-encounters {
    width: 550px;
    max-width: 450px;
  }
}

@media (max-width: 320px) {
  .iph-se-lout {
    font-size: 9pt;
  }
  button.iph-se-lout {
    font-size: 11pt;
  }
  .phone-lout-gc {
    padding-top: 7rem;
  }
  .b-c-se-lout {
    margin-top: 32px;
  }
  .title-cedula {
    font-size: 20px;
  }
  .card-cedula {
    padding: 20px 20px;
  }
  .e-m-width {
    width: auto;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .iph-se-lout {
    font-size: 9pt;
  }
  button.iph-se-lout {
    font-size: 11pt;
  }
  .phone-lout-gc {
    padding-top: 7rem;
  }
  /* .b-c-se-lout {
    margin-top: 10px;
  } */
  .title-cedula {
    font-size: 25px;
  }
  .e-m-width {
    width: auto;
  }
}
