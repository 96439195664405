.card-cedula {
  width: 450px;
}

.eye-off-i,
.eye-show-i {
  position: relative;
  bottom: 32px;
  left: 330px;
}

/* most of the phones screen size */
@media (max-width: 480px) {
  .card-cedula {
    width: auto;
    line-height: 2;
  }
}

/* screen size similar to iPhone SE */
@media (max-width: 320px) {
  .card-cedula {
    width: auto;
    line-height: 2;
  }
}

/* screen size similar to iPhone 8  */
@media (min-width: 320px) and (max-width: 375px) {
  .card-cedula {
    width: auto;
    line-height: 2;
  }
}
